<template>
  <div class="logout">
    {{ $AUTH('LOG_OUT_FORM').LABEL.LOG_OUT_PROCESS }}
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'logout',
  async created () {
    this.setSearchTerm(false)
    await this.logout({ setupRequired: this.setupRequired, audit: true })
  },
  props: {
    setupRequired: {
      default: false
    }
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('insight', [
      'setSearchTerm'
    ])
  }
}
</script>
