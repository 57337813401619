<template>
  <auth-container>
    <b-card class="va-card login">
      <template v-slot:header>
        <h1 class="mb-0">{{ $AUTH('LOG_IN_FORM').TITLE }}</h1>
      </template>
      <form class="mb-1" @submit.prevent="submit" v-data-cy="'form'">
        <b-form-group
          :label="$AUTH('LOG_IN_FORM').LABEL.USERNAME"
          label-for="username-input"
          label-align="left"
          :state="isValid('username')"
          :invalid-feedback="$AUTH('LOG_IN_FORM').INVALID_FEEDBACK.EMPTY_USERNAME"
          v-data-cy="'username'"
        >
          <b-form-input
            id="username-input"
            v-model="$v.username.$model"
            @blur="$v.username.$touch"
            :state="isValid('username')"
          />
        </b-form-group>
        <b-form-group
          :label="$AUTH('LOG_IN_FORM').LABEL.PASSWORD"
          label-for="password-input"
          label-align="left"
          :state="isValid('password')"
          :invalid-feedback="$AUTH('LOG_IN_FORM').INVALID_FEEDBACK.EMPTY_PASSWORD"
          v-data-cy="'password'"
        >
          <b-form-input
            id="password-input"
            v-model="$v.password.$model"
            @blur="$v.password.$touch"
            type="password"
            :state="isValid('password')"
          />
        </b-form-group>
        <b-btn class="submit" block variant="default" type="submit" v-data-cy="'submit'">
            <span v-if="loading === false">{{ $AUTH('LOG_IN_FORM').ACTIONS.LOG_IN }}</span>
            <b-spinner small v-if="loading === true" :label="$AUTH('LOG_IN_FORM').LABEL.SPINNER_LOADING"></b-spinner>
        </b-btn>
      </form>
      <b-alert variant="danger" v-data-cy="'error'" show class="error" v-if="errorCode !== 0 && loading === false">
        <div class="message">
          {{ error.detail }}
        </div>
      </b-alert>
      <a v-data-cy="'forget-password'" href="#" v-b-tooltip.hover.bottom :title="`${$AUTH('LOG_IN_FORM').INVALID_FEEDBACK.FORGET_PASSWORD_MESSAGE}`">Forgot password?</a>
    </b-card>
  </auth-container>
</template>

<script>
import AuthContainer from '../components/Container'
import { mapGetters, mapActions, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import AUTH from '@/constants/AUTH.json'
import ToastMessage from '@/utils/toast_message'

export default {
  name: 'login',
  components: {
    AuthContainer
  },
  mixins: [validationMixin],
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  data () {
    return {
      username: null,
      password: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      loading: 'authenticating',
      error: 'authenticationError',
      errorCode: 'authenticationErrorCode'
    }),
    ...mapState('auth', {
      logoutError: 'logoutError',
      logoutErrorCode: 'logoutErrorCode'
    })
  },
  props: {
    setupRequired: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'clearErrors'
    ]),
    isValid (key) {
      if (this.$v[key].$error === false && this.$v[key].$dirty === false) return null
      return !this.$v[key].$error
    },
    async submit () {
      if (this.$v.$invalid === true) {
        this.$v.username.$touch()
        this.$v.password.$touch()
        return false
      }

      if (this.loading === true) return false

      await this.login({
        username: this.username,
        password: this.password
      })
    }
  },
  mounted () {
    if (this.setupRequired) {
      this.$bvToast.toast(AUTH.LOG_IN_FORM.TOAST.SETUP_REQUIRED.MESSAGE, {
        title: AUTH.LOG_IN_FORM.TOAST.SETUP_REQUIRED.TITLE,
        autoHideDelay: 5000,
        variant: 'danger',
        opacity: 1
      })
    } else if (this.logoutError !== '') {
      // Handle logout error
      ToastMessage.showErrorDefault({ vueInstance: this, textMessage: this.logoutError })
    } else {
      this.clearErrors()
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  text-align: left;
  margin-top: 1rem;
}
.btn.submit {
  &:focus {
      box-shadow: 0 0 0 0.2rem rgba(130,138,145,.5);
  }
}
svg {
  width: 1.5em;
  height: 1.5em;
  cursor: default;
  pointer-events: bounding-box; // so that hovering works on entire svg including empty spaces

  :hover {
    fill: #F08521;
    color: #F08521;
    text-decoration: none;
  }
}

.login {
  h1 {
    font-size: 1.5rem;
  }
}

</style>
