<template>
  <auth-container>
    <b-card class="va-card login">
      <template v-slot:header>
        <h1 class="mb-0">
          {{ $AUTH('OTP_DEVICE_FORM').TITLE }}
        </h1>
      </template>
      <template>
        <div>
          <h2>
            {{ $AUTH('OTP_DEVICE_FORM').INSTRUCTION }}
            <la-info-circle
              v-b-tooltip.hover
              :title="$AUTH('OTP_DEVICE_FORM').TOOLTIP_TEXT"
              class="info-circle"
            />
          </h2>
        </div>
        <img
          :src="'data:image/svg+xml;base64,'+ imageBytes"
          class="qr-code"
        >
      </template>
      <template>
        <b-container class="container">
          <b-row>
            <b-col
              class="mx-auto p-1"
              cols="12"
              sm="8"
              md="7"
              lg="6"
              xl="12"
            >
              <OtpInput
                ref="otpInput"
                class="otpinput"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                @on-complete="handleOnComplete"
                @not-filled="handleNotFilled"
              />
            </b-col>
          </b-row>
        </b-container>
        <b-button
          class="submit"
          block
          variant="default"
          type="submit"
          @click="onSubmit"
        >
          <span v-if="authenticating !== true">{{ $AUTH('OTP_DEVICE_FORM').ACTIONS.REGISTER }}</span>
          <b-spinner
            v-if="authenticating === true"
            small
            :label="$AUTH('LOG_IN_FORM').LABEL.SPINNER_LOADING"
          />
        </b-button>
        <b-alert
          :show="showAlert"
          variant="danger"
          class="error"
        >
          <span v-if="otpNotFilled">{{ $AUTH('OTP_DEVICE_FORM').MISSING_DIGITS }}</span>
          <span v-else>{{ this.otpErrorMessage }}</span>
        </b-alert>
        <div class="code-section">
          <h2>{{ $AUTH('OTP_DEVICE_FORM').INPUT_AUTH_STRING_INSTRUCTION }}</h2>
          <h2 class="authenticator-code">
            {{ displayAuthenticatorCode }}
           <b-button class="clipboard" @click="copyAuthenticatorCodeToClipboard">
            <clipboard class="clipboard-svg" viewBox="0 0 16 16" v-b-tooltip.hover :title="$AUTH('OTP_DEVICE_FORM').COPY"/>
          </b-button>
          </h2>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <router-link :to="{ name: 'logout' }">
            <span class="authentication-cancel">
              {{ $AUTH('OTP_DEVICE_FORM').CANCEL }}
            </span>
          </router-link>
        </div>
      </template>
    </b-card>
    <b-modal id="my-modal">
      {{ $AUTH('OTP_DEVICE_FORM').MODAL_MESSAGE }}
    </b-modal>
  </auth-container>
</template>

<script>
import AuthContainer from '@/modules/auth/components/Container'
import OtpInput from '@/modules/auth/components/OtpInput.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import LaInfoCircle from '@/assets/la-info-circle.svg'
import Clipboard from '@/assets/clipboard.svg'

export default {
  name: 'DeviceAuth',
  components: {
    AuthContainer,
    OtpInput,
    LaInfoCircle,
    Clipboard
  },
  data () {
    return {
      imageBytes: '',
      authenticatorCode: '',
      oneTimePass: '',
      routingInProgress: false,
      showAlert: false,
      otpNotFilled: true
    }
  },
  computed: {
    ...mapState('auth', ['otpDeviceRegisteredWithUser', 'otpErrorMessage', 'otpErrorCode']),
    ...mapGetters('auth', ['authenticating']),
    /**
     * This function adds a space to every 4 characters of the authenticator code for better readability.
     * @return {string|string} The space formatted string if authenticator code is not empty
     */
    displayAuthenticatorCode () {
      return this.authenticatorCode ? this.authenticatorCode.toString().match(/.{1,4}/g).join(' ') : this.authenticatorCode
    }
  },
  async mounted () {
    await this.createQrCodeAndAuthenticationString()
    // for browser refresh
    window.addEventListener('beforeunload', this.refreshEvent)
  },
  destroyed () {
    // Remove the popup event listener after leaving this page
    window.removeEventListener('beforeunload', this.refreshEvent)
  },
  methods: {
    ...mapActions('auth', [
      'getUser2FaDeviceQrCodeBase64String',
      'postOtpCodeToRegisterDeviceAsTotpToken'
    ]),
    refreshEvent (event) {
      // Cancel the event
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
    },
    async createQrCodeAndAuthenticationString () {
      const response = await this.getUser2FaDeviceQrCodeBase64String()
      this.imageBytes = response.encoded_img
      this.authenticatorCode = response.encoded_key
    },
    handleOnComplete (value) {
      this.otpNotFilled = false
      this.oneTimePass = value
      this.showAlert = false
    },
    handleErrorAlert () {
      this.showAlert = (this.otpErrorCode !== 0 && !this.authenticating) || this.otpNotFilled
    },
    handleNotFilled () {
      this.otpNotFilled = true
    },
    async onSubmit () {
      if (!this.otpNotFilled) {
        const response = await this.postOtpCodeToRegisterDeviceAsTotpToken(this.oneTimePass)
        if (response) {
          this.routingInProgress = true
          await this.$router.push({ name: 'logout' })
        }
      }
      this.handleErrorAlert()
    },
    copyAuthenticatorCodeToClipboard () {
      navigator.clipboard.writeText(this.authenticatorCode)
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (!this.routingInProgress) {
      const value = await this.$bvModal.msgBoxConfirm(this.$AUTH('OTP_DEVICE_FORM').MODAL_MESSAGE, {
        title: this.$AUTH('OTP_DEVICE_FORM').MODAL_TITLE,
        size: 'md',
        buttonSize: 'md',
        okVariant: 'success',
        cancelVariant: 'danger',
        okTitle: this.$AUTH('OTP_DEVICE_FORM').MODAL_BUTTON,
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false,
        noCloseOnBackdrop: true
      })
      if (value) {
        this.routingInProgress = true
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}

</script>

<style scoped lang="scss">

.error {
  margin-top: 1rem;
}

.info-circle {
  height: 20px;
  margin-left: 1px;
  margin-bottom: 1px;
}

.qr-code {
  height: 200px;
  width: 200px;
}

.authenticator-code {
  line-height: 1.2rem;
  margin-bottom: 0px !important;
}

.clipboard {
  margin-left: 0.1rem;
  background: transparent;
  border-color: transparent;
  border-radius: 100%;
  padding: 0px 2px 0px 2px;
  vertical-align: top;
  line-height: 0.7rem;
  font-size: 0.5rem;

  .clipboard-svg {
    width: 0.7rem;
    fill: #545459;
  }

  &.active, &:hover{
    background: #F08521;
    border-color: #e87810;
    .clipboard-svg {
      fill: white;
    }
  }
}

.authentication-cancel {
  font-size: 0.7rem;
  font-weight: 300;
  text-decoration: underline;
}

@media only screen and (min-width: 1200px) {
  .otpinput {
    margin-left: 5px;
  }
}

.container {
  margin-bottom: 10px;
}

.userInput {
  display: flex;
  justify-content: center;
}

.login {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1rem;
  }

  .code-section {
    margin-top: 1rem;

    h2 {
      font-size: 0.7rem;
    }
  }
}

.error {
  margin-top: 1rem;
}

.card-footer {
  // To amend css for v-slot:footer just for this scope
  background: #FFF !important;
  border-top: none !important;
  text-align: left;
}

</style>
