<template>
  <div class="change-password-auth">
    <b-container>
      <b-row class="justify-content-center">
        <b-col sm="8">
          <b-card class="va-card change-password">
            <template v-slot:header>
              <h1 class="mb-0">{{ $AUTH('CHANGE_PASSWORD_FORM').TITLE }}</h1>
            </template>
            <GenericFormField
              v-for="(option, key) in passwordFieldOptions"
              :key="key"
              :fieldId="key"
              :field="option"
              v-model="option.data"
              :readOnly="false"
              :error="error"
              :errorCode="errorCode"
              :visible="true"
            >
              <template v-slot:belowInput>
                <div
                  v-if="key === 'new_password2'"
                >
                  <b-alert class="help_text" show variant="info">
                    <ul>
                      <li
                        v-for="(help_text, key) in helpTextForPassword"
                        :key="key"
                      >
                        {{ `${help_text}` }}
                      </li>
                    </ul>
                  </b-alert>
                </div>
              </template>
            </GenericFormField>
            <div class="d-flex justify-content-between mt-4">
              <b-btn variant="danger" @click="routeBack">
                {{ $AUTH('CHANGE_PASSWORD_FORM').ACTIONS.CANCEL }}
              </b-btn>
              <b-btn class="submit" variant="success" type="submit" @click="submit">
                <span v-if="!loading">{{ $AUTH('CHANGE_PASSWORD_FORM').ACTIONS.SUBMIT }}</span>
                <b-spinner small v-if="loading" :label="$AUTH('CHANGE_PASSWORD_FORM').LABEL.SPINNER_LOADING"></b-spinner>
              </b-btn>
            </div>
            <error-handler
              v-if="showError === true"
              :error="displayError"
              :dismissible="false"
              variant="danger"
              :showError="showError"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import StringFormatUtils from '@/utils/string_formatter'
import GenericFormField from '@/modules/config/components/GenericFormField'
import ErrorHandler from '@/modules/insight/components/ErrorHandler'

export default {
  name: 'change-password',
  components: {
    ErrorHandler,
    GenericFormField
  },
  data () {
    return {
      helpTextForPassword: [],
      prevRoute: null,
      showError: false,
      displayError: {
        code: 0,
        message: ''
      }
    }
  },
  computed: {
    ...mapState('auth', [
      'passwordFieldOptions'
    ]),
    ...mapGetters('auth', {
      loading: 'authenticating',
      error: 'authenticationError',
      errorCode: 'authenticationErrorCode'
    })
  },
  async mounted () {
    await this.getPasswordHelpText()
    await this.getPasswordFieldOptions()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    ...mapActions('auth', [
      'getPasswordOptionHelpText',
      'getPasswordFieldOptions',
      'changePassword',
      'clearErrors'
    ]),
    async submit () {
      if (this.loading) {
        return false
      }

      const response = await this.changePassword({
        oldPassword: this.passwordFieldOptions.old_password.data,
        newPassword: this.passwordFieldOptions.new_password1.data,
        confirmPassword: this.passwordFieldOptions.new_password2.data
      })

      // Only logout if change password is successful
      if (response) {
        this.$router.push({ name: 'logout' })
      } else {
        return false
      }
    },
    async getPasswordHelpText () {
      try {
        this.clearErrors()
        const helpTextForPasswordString = await this.getPasswordOptionHelpText()
        this.helpTextForPassword = StringFormatUtils.formatHelpText(helpTextForPasswordString)
      } catch (e) {
        this.helpTextForPassword = e.error
      }
    },
    routeBack () {
      this.$router.push(this.prevRoute.path)
    }
  },
  watch: {
    errorCode (err) {
      if (err === 0) {
        // Validation Error
        this.showError = false
        this.displayError = {
          code: 0,
          message: ''
        }
      } else {
        this.showError = true
        this.displayError = {
          code: err,
          message: this.$INSIGHT('ERROR').SUBMISSION_ERROR
        }
      }
    }
  }
}
</script>

<style scoped lang='scss'>
  .help_text {
    text-align: left
  }
</style>
