<template>
  <auth-container>
    <b-card class="va-card login">
      <template v-slot:header>
        <h1 class="mb-0">
          {{ $AUTH('OTP_FORM').TITLE }}
        </h1>
      </template>
      <template>
        <b-container class="container">
          <h2>{{ $AUTH('OTP_FORM').INSTRUCTION }}</h2>
          <b-row>
            <b-col
              class="mx-auto p-1"
              cols="12"
              sm="8"
              md="7"
              lg="6"
              xl="12"
            >
              <OtpInput
                ref="otpInput"
                class="otpinput"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                @on-complete="handleOnComplete"
                @not-filled="handleNotFilled"
              />
            </b-col>
          </b-row>
        </b-container>
        <b-button
          class="submit"
          block
          variant="default"
          type="submit"
          @click="onSubmit"
        >
          <span v-if="verifying === false">{{ $AUTH('OTP_FORM').ACTIONS.VALIDATE_OTP }}</span>
          <b-spinner
            v-if="verifying === true"
            small
            :label="$AUTH('LOG_IN_FORM').LABEL.SPINNER_LOADING"
          />
        </b-button>
        <b-alert
          :show="showAlert"
          variant="danger"
          class="error"
        >
          <span v-if="otpNotFilled">{{ $AUTH('OTP_DEVICE_FORM').MISSING_DIGITS }}</span>
          <span v-else>{{ this.otpErrorMessage }}</span>
        </b-alert>
      </template>
      <template v-slot:footer>
        <router-link :to="{ name: 'logout' }">
          <span class="authentication-cancel">
            {{ $AUTH('OTP_FORM').CANCEL }}
          </span>
        </router-link>
      </template>
    </b-card>
  </auth-container>
</template>

<script>
import AuthContainer from '@/modules/auth/components/Container'
import OtpInput from '@/modules/auth/components/OtpInput.vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'OTP',
  components: {
    AuthContainer,
    OtpInput
  },
  data () {
    return {
      oneTimePass: null,
      showAlert: false,
      otpNotFilled: true
    }
  },
  computed: {
    ...mapState('auth', ['otpErrorMessage', 'verifying', 'otpErrorCode'])
  },
  methods: {
    async handleOnComplete (value) {
      this.otpNotFilled = false
      this.oneTimePass = value
      this.showAlert = false
    },
    handleErrorAlert () {
      this.showAlert = (!this.verifying && this.otpErrorCode !== 0) || this.otpNotFilled
    },
    handleNotFilled () {
      this.otpNotFilled = true
    },
    async onSubmit () {
      if (!this.otpNotFilled) {
        await this.postOtpCodeToVerifyUser(this.oneTimePass)
      }
      this.handleErrorAlert()
    },
    ...mapActions('auth', [
      'postOtpCodeToVerifyUser'
    ])
  }
}
</script>

<style scoped lang="scss">

@media only screen and (min-width: 1200px) {
  .otpinput {
    margin-left: 5px;
  }
}

.container {
  margin-bottom: 10px;
}

.userInput {
  display: flex;
  justify-content: center;
}

.login {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1rem;
  }
}

.error {
  margin-top: 1rem;
}

.card-footer {
  // To amend css for v-slot:footer just for this scope
  background: #FFF !important;
  border-top: none !important;
  text-align: left;
}

.authentication-cancel {
  font-size: 0.7rem;
  font-weight: 300;
  text-decoration: underline;
}

</style>
