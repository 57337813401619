<template>
  <div class="single-otp-input">
    <input
      ref="input"
      v-model="model"
      :type="inputType"
      min="0"
      max="9"
      maxlength="1"
      :class="inputClasses"
      @input="handleOnChange"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    >
    <span v-if="!isLastChild && separator">
      <span>{{ separator }}</span>
    </span>
  </div>
</template>

<script>

export default {
  name: 'SingleOtpInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    separator: {
      type: String,
      default: '-'
    },
    focus: {
      type: Boolean
    },
    inputClasses: {
      type: String
    },
    shouldAutoFocus: {
      type: Boolean
    },
    inputType: {
      type: String,
      default () {
        return 'text'
      }
    },
    isLastChild: {
      type: Boolean
    }
  },
  data () {
    return {
      model: this.value || ''
    }
  },
  watch: {
    /**
    * value is the value of the singleOTP input at a specific index. If the input changes and
    * after parent component functions run to update otp value, it will update the current
    * value of this input at the specific index
    * @param {Number} newValue
    * @param {Number} oldValue
    */
    value (newValue, oldValue) {
      this.model = newValue
    },
    // whenever focus changes, this function will run
    focus (newFocusValue, oldFocusValue) {
      // Check if focusedInput changed
      this.$refs.input.focus()
      this.$refs.input.select()
    }
  },
  mounted () {
    if (this.$refs.input && this.focus && this.shouldAutoFocus) {
      this.$refs.input.focus()
    }
  },
  methods: {
    /**
    * is listening to input value via v-model bidning. It will emit the value in the input to parent.
    */
    handleOnChange () {
      if (this.model.length > 1) {
        this.model = this.model.slice(0, 1)
      }
      this.$emit('on-change', this.model)
    },
    /**
    * handles KeyBoardEvent when keydown input occurs on otp component
    * Extracts code attribute and checks if code is an integer or any other
    * defined special constant before allowing it to be input into the component
    * otherwise rejected.
    * @param {KeyBoardEvent} event
    */
    handleOnKeyDown (event) {
      const code = event.code
      if (this.$LOCAL('KEYBOARD_EVENT_CODE').NUMBER_CODES.includes(code)) {
        this.$emit('on-keydown', event)
      } else if (code === this.$LOCAL('KEYBOARD_EVENT_CODE').BACKSPACE) {
        this.$emit('on-keydown', event)
      } else if (code === this.$LOCAL('KEYBOARD_EVENT_CODE').DELETE) {
        this.$emit('on-keydown', event)
      } else if (code === 'KeyV' && event.ctrlKey === true) {
        this.$emit('on-keydown', event)
      } else if ([this.$LOCAL('KEYBOARD_EVENT_CODE').LEFT_ARROW, this.$LOCAL('KEYBOARD_EVENT_CODE').RIGHT_ARROW].includes(code)) {
        this.$emit('on-keydown', event)
      } else {
        event.preventDefault()
      }
    },
    isCodeNumeric (charCode) {
      // numeric keys and numpad keys
      return this.$LOCAL('KEYBOARD_EVENT_CODE').NUMBER_CODES.includes(charCode)
    },
    handleOnPaste (event) {
      return this.$emit('on-paste', event)
    },
    handleOnFocus () {
      this.$refs.input.select()
      return this.$emit('on-focus')
    },
    handleOnBlur () {
      return this.$emit('on-blur')
    }
  }
}
</script>

<style lang="scss" scoped>

.input {
  display: flex;
  align-items: center;
}

.single-otp-input {
  display: flex;
  align-items: center;
}

.otp-input {
  width: 35px;
  height: 35px;
  padding: 5px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  &.error {
    border: 1px solid red !important;
  }
}

</style>
