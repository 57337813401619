<template>
  <div class="d-flex">
    <!--    To turn off autocomplete when otp-input is password-->
    <input
      v-if="inputType === 'password'"
      autocomplete="off"
      name="hidden"
      type="text"
      style="display:none;"
    >
    <SingleOtpInput
      v-for="(item, i) in numInputs"
      :key="i"
      :focus="activeInput === i"
      :value="otp[i]"
      :separator="separator"
      :input-type="inputType"
      :input-classes="inputClasses"
      :is-last-child="i === numInputs - 1"
      :should-auto-focus="shouldAutoFocus"
      @on-change="handleOnChange"
      @on-keydown="handleOnKeyDown"
      @on-paste="handleOnPaste"
      @on-focus="handleOnFocus(i)"
      @on-blur="handleOnBlur"
    />
  </div>
</template>

<script>
import SingleOtpInput from './SingleOtpInput'
export default {
  name: 'OtpInput',
  components: {
    SingleOtpInput
  },
  props: {
    numInputs: {
      type: Number,
      default: 4
    },
    separator: {
      type: String,
      default: '**'
    },
    inputClasses: {
      type: String
    },
    inputType: {
      type: String,
      validator (value) {
        return ['number', 'text', 'password'].includes(value)
      },
      default: 'text'
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeInput: 0,
      otp: [],
      oldOtp: []
    }
  },
  methods: {
    handleOnFocus (index) {
      this.activeInput = index
    },
    handleOnBlur () {
      this.activeInput = -1
    },
    /**
    * Checks if all inputs are filled and emits to component
    * @param {}
    * @return {}
    */
    checkFilledAllInputs () {
      if (this.otp.join('').length === this.numInputs) {
        this.$emit('on-complete', this.otp.join(''))
      } else {
        this.$emit('not-filled')
      }
    },
    /**
    * Focus input by index. If you have 6 numInputs, it will give you a range 0 to 5
    * to focus your input. Math.min is to ensure that your range never exceeds numInputs - 1
    * Math.max is to ensure you get the correct activeInput.
    * @param {Integer} input activeinput variable
    */
    focusInput (input) {
      this.activeInput = Math.max(Math.min(this.numInputs - 1, input), 0)
    },
    // Focus on next input
    focusNextInput () {
      this.focusInput(this.activeInput + 1)
    },
    // Focus on previous input
    focusPrevInput () {
      this.focusInput(this.activeInput - 1)
    },
    /**
    * When there is a new input in singleOTPInput component, it emits an event which triggers
    * a handler which runs changeCodeAtFocus. This will emit the value of the current code to the
    * parent component if there is any change.
    * @param {Number} value value of input event
    */
    changeCodeAtFocus (value) {
      this.oldOtp = Object.assign([], this.otp)
      this.$set(this.otp, this.activeInput, value)
      if (this.oldOtp.join('') !== this.otp.join('')) {
        this.$emit('on-change', this.otp.join(''))
        this.checkFilledAllInputs()
      }
    },
    /**
    * Handles pasting event. See the mozilla documentation for more info on ClipBoardEvent
    * @param {ClipboardEvent}
    * @return {String}
    */
    handleOnPaste (event) {
      event.preventDefault()
      const pastedData = event.clipboardData
        .getData('text/plain')
        .slice(0, this.numInputs - this.activeInput)
        .split('')
      // uses regex to filter non number
      if (this.inputType === 'number' && pastedData.join('').match(/^\d+$/) === null) {
        // cancels function call by returning
        return 'Invalid pasted data'
      }
      // Paste data from focused input onwards
      const currentCharsInOtp = this.otp.slice(0, this.activeInput)
      const combinedWithPastedData = currentCharsInOtp.concat(pastedData)
      this.$set(this, 'otp', combinedWithPastedData.slice(0, this.numInputs))
      this.focusInput(combinedWithPastedData.slice(0, this.numInputs).length)
      this.checkFilledAllInputs()
    },
    /**
    * when singleOTPinput has a new input event, the value emitted from it will update the
    * otp at specific index with this value by triggering handleOnChange which runs changeCodeAtFocus
    * @param {Integer} value from singleOTPinput input event
    */
    handleOnChange (value) {
      this.changeCodeAtFocus(value)
      this.focusNextInput()
    },
    clearInput () {
      if (this.otp.length > 0) {
        this.$emit('on-change', '')
      }
      this.otp = []
      this.activeInput = 0
    },
    // Handle cases of backspace, delete, left arrow, right arrow
    handleOnKeyDown (event) {
      switch (event.code) {
        case this.$LOCAL('KEYBOARD_EVENT_CODE').BACKSPACE:
          event.preventDefault()
          this.changeCodeAtFocus('')
          this.focusPrevInput()
          break
        case this.$LOCAL('KEYBOARD_EVENT_CODE').DELETE:
          event.preventDefault()
          this.changeCodeAtFocus('')
          break
        case this.$LOCAL('KEYBOARD_EVENT_CODE').LEFT_ARROW:
          event.preventDefault()
          this.focusPrevInput()
          break
        case this.$LOCAL('KEYBOARD_EVENT_CODE').RIGHT_ARROW:
          event.preventDefault()
          this.focusNextInput()
          break
        default:
          break
      }
    }
  }
}
</script>
