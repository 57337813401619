<template>
  <div class="dismissible-error error fade-in-fast">
    <b-alert :variant="variant" :dismissible="dismissible" fade :show="showError" @dismissed="$emit('dismiss-error')">
      <la-warning />
      <div class="message">
        <span v-if="error.code > 0">{{ error.code }}<br/></span>
        <span v-if="error.code === 404">{{this.$INSIGHT('ERROR').CODE_404}}</span>
        <span v-else-if="serverError500">{{serverError500}}</span>
        <span v-else-if="error.message !== ''">{{ error.message }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import LaWarning from '@/assets/la-warning.svg'

export default {
  name: 'Error',
  props: {
    error: {
      // type: [Object, Boolean],
      required: true
    },
    showError: {
      // type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'danger'
    },
    dismissible: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LaWarning
  },
  computed: {
    serverError500 () {
      // To change 500 error that returned from backend to a custom error
      const serverErrorMsg = this.$INSIGHT('ERROR').CODE_500.IDENTIFIER
      if (this.error?.message.includes(serverErrorMsg)) {
        return this.$INSIGHT('ERROR').CODE_500.CUSTOM_MESSAGE
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  overflow: auto;
  max-height: 30vh;
  margin: 25px 0;
  text-align: center;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
}

svg {
  width: 50px;
  height: 50px;
}
</style>
