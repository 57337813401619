<template>
  <div class="auth">
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="12" sm="12" md="10" lg="8" xl="4">
          <slot />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AuthContainer'
}
</script>

<style lang="scss" scoped>
</style>
