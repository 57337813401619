<template>
  <div>
    <b-form-group
      v-if="visible"
      :label="field.label"
      :label-for="fieldId"
      label-align="left"
      :invalid-feedback="errorMessage"
      :state="valid"
    >
      <template v-slot:label>
        <span>{{ field.label }}</span>
        <slot name="tooltip">
          <LaInfoCircle v-if="field.tooltipText" v-b-tooltip.hover :title="field.tooltipText" class="info-circle" />
        </slot>
      </template>
      <div class="d-flex">
        <b-form-input
          v-if="field.type === 'string' || field.type === 'email' || field.type === 'password'"
          :id="fieldId"
          :type="inputType"
          :value="value"
          @input="$emit('input', $event)"
          :state="valid"
          :required="field.required"
          :readonly="readOnly"
          :placeholder="parsedPlaceholder"
          trim
        />
        <b-form-select
          v-if="field.type === 'choice'"
          :value="value"
          @input="$emit('input', $event)"
          :options="options"
          :required="field.required"
          :disabled="readOnly"
        ></b-form-select>
        <div>
          <b-form-checkbox
          v-if="field.type === 'checkbox'"
          @change="$emit('selectAll', $event)"
          :checked="selectAll"
          :disabled="readOnly"
        >
          Select All
          </b-form-checkbox>
          <b-form-checkbox-group
            v-if="field.type === 'checkbox'"
            @input="$emit('input', $event)"
            :options="checkboxGroupOptions"
            :checked="value"
            :disabled="readOnly"
          />
        </div>
        <b-form-checkbox
          v-if="field.type === 'switch'"
          class="text-left"
          @input="$emit('input', $event)"
          :checked="value"
          :disabled="readOnly"
          switch
        ><strong>{{ field.switchLabel }}</strong>
        </b-form-checkbox>
        <slot name="besideInput"></slot>
      </div>
    </b-form-group>
    <slot name="belowInput"></slot>
  </div>
</template>

<script>
import LaInfoCircle from '@/assets/la-info-circle.svg'

export default {
  name: 'GenericFormField',
  components: {
    LaInfoCircle
  },
  props: {
    fieldId: {
      type: String
    },
    field: {
      default: () => ({})
    },
    value: {
      type: [String, Array, Boolean, null]
    },
    errorCode: {
      type: Number
    },
    error: {
      default: () => ({})
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    checkboxGroupOptions: {
      default: () => []
    },
    showHelpText: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    valid: null,
    allSelected: false,
    selectAll: false,
    validPassword: null
  }),
  computed: {
    /**
     * If inputType is provided, return inputType.
     * If field is 'password' or 'confirm_new_password', set default to return password type
     * Otherwise, return text type
     */
    inputType () {
      if (this.field.inputType) {
        return this.field.inputType
      } else if (this.fieldId === 'password' || this.fieldId === 'confirm_new_password' || this.fieldId === 'old_password' || this.fieldId === 'new_password1' || this.fieldId === 'new_password2') {
        return 'password'
      } else {
        return 'text'
      }
    },
    errorMessage () {
      if (Object.prototype.hasOwnProperty.call(this.error, this.fieldId)) {
        return Array.isArray(this.error[this.fieldId])
          ? this.error[this.fieldId].toString()
          : this.error[this.fieldId]
      } else if (
        Object.prototype.hasOwnProperty.call(this.error, 'non_field_errors') &&
        (this.fieldId === 'password' || this.fieldId === 'confirm_new_password' || this.fieldId === 'new_password1' || this.fieldId === 'new_password2')
      ) {
        if (Array.isArray(this.error.non_field_errors)) {
          let err = this.error.non_field_errors
          for (let i = 1; i < err.length; i++) {
            err[i] = ' ' + err[i].toLowerCase()
          }
          err = this.error.non_field_errors.toString()
          err = err.charAt(0).toUpperCase() + err.slice(1)
          return err
        } else {
          return this.error.non_field_errors
        }
      }
      return ''
    },
    options () {
      const options = []
      if (Object.prototype.hasOwnProperty.call(this.field, 'choices')) {
        this.field.choices.forEach(choice => {
          options.push({ value: choice.value, text: choice.display_name })
        })
      }
      return options
    },
    /**
     * @public
     * This computed property returns an empty string if field is readOnly else it returns the help text. Used as the placeholder for form input.
     * @returns {string}
     */
    parsedPlaceholder () {
      return this.fieldId === 'password' || this.readOnly
        ? ''
        : this.field.help_text
    }
  },
  methods: {},
  watch: {
    value (newValue) {
      if (newValue) this.valid = null
      if (newValue?.length === 0) {
        this.selectAll = false
      } else if (newValue?.length === this.checkboxGroupOptions.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },
    errorCode (val) {
      if (val === 400 && this.errorMessage !== '') {
        this.valid = false
        this.validPassword = false
      } else {
        this.validPassword = null
        this.valid = null
      }
    }
  }
}
</script>

<style scoped>
.info-circle {
  height: 18px;
  margin-left: 5px;
}
</style>
